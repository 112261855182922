@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

body {
  margin: 0;
  font-family: "DM Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* Reduce the scrollbar width while keeping arrows */
body::-webkit-scrollbar {
  width: 8px; /* Narrow scrollbar */
}

/* Style the scrollbar track (background area) */
body::-webkit-scrollbar-track {
  background: transparent; /* Light grey background */
  margin: 2px !important; /* Add space to retain the arrow buttons */
}

/* Style the scrollbar thumb (draggable part) */
body::-webkit-scrollbar-thumb {
  background: #c2c2c2; /* Dark grey color */
  border-radius: 6px; /* Rounded edges */
}

/* Hover effect for the scrollbar thumb */
body::-webkit-scrollbar-thumb:hover {
  background: #9e9e9e; /* Even darker grey on hover */
}


.scrollbar::-webkit-scrollbar {
  width: 6px; /* Narrow scrollbar */
}

/* Style the scrollbar track (background area) */
.scrollbar::-webkit-scrollbar-track {
  background: transparent; /* Light grey background */
  margin: 10px !important; /* Add space to retain the arrow buttons */
}

/* Style the scrollbar thumb (draggable part) */
.scrollbar::-webkit-scrollbar-thumb {
  background: #c2c2c2; /* Dark grey color */
  border-radius: 6px; /* Rounded edges */
}

/* Hover effect for the scrollbar thumb */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #9e9e9e; /* Even darker grey on hover */
}

/* Add padding back to retain the arrows 
.scrollbar::-webkit-scrollbar-button {
  background: white;
}
*/

i{
  cursor: pointer !important;
}

.custom-tooltip {
  background: #1E1E2F;
  color: #FFF;
  padding: 12px;
  border-radius: 4px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  line-height: 1.5;
  min-width: 180px;
  max-width: 350px;
  white-space: nowrap; /* Prevent text wrapping */
}

.tooltip-header {
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 8px;
  color: #FFD700;
}

.tooltip-content {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.tooltip-project {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tooltip-project span {
  font-weight: 500;
  color: #D1D5DB;
  min-width: 200px; /* Fixed width for project names */
  display: inline-block;
  text-align: left;
}

.tooltip-project strong {
  text-align: right;
  flex-shrink: 0; /* Prevents shrinking */
}
.total_dash{
  margin-top: 6px;
}

.dashboard-custom-select{
  cursor: pointer;
}

.dashboard-custom-select option {
  font-size: 16px;
  background: #fff; /* Ensures visibility */
  color: #333;
  padding: 10px;
}

.dashboard-custom-select:focus {
  border: "none"
}

.dashboard-custom-select:focus-visible {
  outline: none !important;   /* Remove outline */
  box-shadow: none !important; /* Remove shadow (if any) */
}